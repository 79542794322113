import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

export default function Index() {
  useEffect(() => {
    navigate(`/1`, { replace: true });
  }, []);

  return <div />;
}
